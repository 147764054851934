import React, { FC } from "react";
import {
  createBrowserRouter,
  RouteObject,
  RouterProvider,
} from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import { LicenseInfo } from "@mui/x-license";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { BootResult } from "@airmont/shared/ts/ui/app-boot";
import { AppThemePage } from "@airmont/firefly/shared/ts/ui";
import {
  AboutPage,
  BurnsPage,
  CombustionPlantPage,
  GuidancePage,
  HistoryPage,
  KeyFiguresPage,
  MyChimneyContextLoader,
  RootPage,
  UserPage,
} from "@airmont/firefly/my-chimney/ts/pages";
import * as Sentry from "@sentry/react";

LicenseInfo.setLicenseKey(
  "5123f2d5adb498197b4af8fb52add066Tz03ODY3OCxFPTE3MzE1NzMzODEwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y"
);

export interface AppProps {
  bootResult: BootResult;
}

export const App: FC<AppProps> = (props) => {
  const rootRoute: RouteObject = {
    path: "/",
    element: (
      <QueryParamProvider adapter={ReactRouter6Adapter}>
        <RootPage />
      </QueryParamProvider>
    ),
    //errorElement: <ErrorPage/>,
    children: [
      {
        children: [
          {
            index: true,
            element: (
              <MyChimneyContextLoader>
                <KeyFiguresPage />
              </MyChimneyContextLoader>
            ),
          },
          {
            path: "/burns",
            element: (
              <MyChimneyContextLoader>
                <BurnsPage />
              </MyChimneyContextLoader>
            ),
          },
          {
            path: "/guidance",
            element: <GuidancePage />,
          },
          {
            path: "/history",
            element: (
              <MyChimneyContextLoader>
                <HistoryPage />
              </MyChimneyContextLoader>
            ),
          },
          {
            path: "/combustion-plant",
            element: (
              <MyChimneyContextLoader>
                <CombustionPlantPage />
              </MyChimneyContextLoader>
            ),
          },
          {
            path: "/app-theme",
            element: <AppThemePage />,
          },
          {
            path: "/user",
            element: <UserPage />,
          },
          {
            path: "/about",
            element: <AboutPage />,
          },
        ],
      },
    ],
  };

  const routes: Array<RouteObject> = [rootRoute];
  const sentryCreateBrowserRouter =
    Sentry.wrapCreateBrowserRouter(createBrowserRouter);
  const router = sentryCreateBrowserRouter(routes);
  return (
    <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={"no"}>
      <RouterProvider router={router} />
    </LocalizationProvider>
  );
};
